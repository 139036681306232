<template>
  <v-fade-transition mode="out-in">
    <section v-if="loading" class="flex loading">
      <div class="container">
        <h2 class="loading-text">
          {{ $t("loading") }}
        </h2>
        <base-loading class="spinner" />
      </div>
    </section>
    <router-view v-else />
  </v-fade-transition>
</template>

<script>
import { i18n } from "@/plugins/i18n";
import { settingDigitalLetterCtrl } from "@/controllers";
import { setCSSVar } from "@/utils";
import {
  settingsDigitalLetterStoreActions,
  settingsDigitalLetterStoreGetters,
} from "./store/modules/settingsDigitalLetter/constNames";

export default {
  name: "App",
  metaInfo: {
    title: i18n.t("loading"),
    titleTemplate: "%s | Foodeo",
    htmlAttrs: { lang: i18n.locale },
    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
    ],
  },
  data() {
    return {
      loading: true,
    };
  },
  mounted() {
    settingDigitalLetterCtrl
      .getSetting()
      .then((settings) => {
        this.$store.dispatch(
          settingsDigitalLetterStoreActions.SET_SETTINGS_DIGITAL_LETTER,
          settings
        );
        const { bgPageColor, bgHeaderColor, primaryColor } =
          this.$store.getters[settingsDigitalLetterStoreGetters.GET_STYLES];

        bgPageColor && setCSSVar("--bgColor", bgPageColor);
        bgHeaderColor && setCSSVar("--v-secondary-base", bgHeaderColor);
        primaryColor && setCSSVar("--v-primary-base", primaryColor);
      })
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>

<style lang="sass" scoped>
.loading
  background: var(--bgColor)
  height: 100vh
  display: flex
  justify-content: center
  align-items: center
  font-family: $heading-font-family

  &-text
    text-align: center
    text-transform: uppercase
  .spinner
    margin: 20px auto
    justify-content: center
    max-height: max-content
</style>
