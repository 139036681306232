<template>
  <v-row justify="center">
    <v-progress-circular
      :width="15"
      :size="100"
      color="rgba(184, 184, 184,0.4)"
      indeterminate
    />
  </v-row>
</template>
<script>
export default {
  name: "BaseLoading",
};
</script>
