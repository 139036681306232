<template>
  <v-text-field
    outlined
    dense
    v-bind="$attrs"
    v-on="$listeners"
    :append-icon="getShowPasswordIcon"
    :type="getTypeInputPassword"
    @click:append="handleShowPassword"
  >
    <slot slot="append-outer" name="append-outer" />
  </v-text-field>
</template>

<script>
export default {
  name: "BasePasswordField",
  data() {
    return {
      show: false,
    };
  },
  computed: {
    getShowPasswordIcon() {
      return this.show ? "mdi-eye" : "mdi-eye-off";
    },
    getTypeInputPassword() {
      return this.show ? "text" : "password";
    },
  },
  methods: {
    handleShowPassword() {
      this.show = !this.show;
    },
  },
};
</script>

<style lang="sass"></style>
