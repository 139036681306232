<template>
  <v-textarea
    outlined
    dense
    :rules="rulesLetters"
    v-bind="{
      rows: 4,
      ...$attrs,
    }"
    v-on="$listeners"
  />
</template>

<script>
import { rulesLetters } from "@/helpers";

export default {
  name: "BaseTextarea",
  data() {
    return {
      rulesLetters,
    };
  },
};
</script>

<style lang="sass">
//
</style>
